<template>
  <div v-if="data.length" class="set-slider">
    <div class="container-padding">
      <SliderComponent
        :items="data"
        v-slot="{ item }"
        title="Наборы от Зеленого яблока"
        buttonTitle="Посмотреть все"
        :route="{ name: 'sets' }"
        :slider-options="cardsSliderOptions"
        class="set-slider__items"
      >
        <SetBuyCardComponent :data="item" :main="true" :column="true" />
      </SliderComponent>
    </div>
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import SetBuyCardComponent from "components/SetBuyCardComponent.vue";
export default {
  name: "SetSliderComponent",
  data() {
    return {
      cardsSliderOptions: {
        slidesPerView: 1.2,
        spaceBetween: 32,
        pagination: false,
        clickable: false,
        preloadImages: true,
        // loop: true,
        breakpoints: {
          540: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
          800: {
            slidesPerView: 2.3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  props: {
    data: Array,
  },
  components: { SetBuyCardComponent, SliderComponent },
};
</script>

<style lang="stylus">
.set-slider{
  background var(--white-3)
  padding-top 65px
  padding-bottom: 65px
  //.swiper {
  //  border-radius: 16px;
  //}

  &__items {

    & .swiper-wrapper {
      align-items flex-start
    }

    & .swiper-slide {
      background: var(--white-3);
    }
  }
}
</style>
